@import "../mixins.scss";
@import "../variables.scss";

b{
  font-size: 14px;
  color: #003366;
  font-family: 'Futura Heavy';
}
.ramadantermsheading{
  font-size: 20px;
  font-family: 'Futura Heavy';
  color: #003366;
  display: block;
  text-align: center;
}

  p{
    font-size: 13px!important;
    line-height: 1.8;
    letter-spacing: 0.2px;
    margin-top: 10px;
  }
  li{
    font-size: 12px;
  }
  .mycenter {
    text-align: center;
}
.shipping-page-main strong {
  color: #003366;
  font-size:14px;
}
.shipping-page-main .heading {
  display: none;
}