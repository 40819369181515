@import "../../../mixins.scss";
@import "../../../variables.scss";

.payment-method-desktop {
  $title-font-size: 1.3rem;
  $subtitle-font-size: 1rem;
  margin-bottom: 30px;

  .data-change {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    background: #fbfdf4;
    color: #9898a1;
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    .dismiss {
      padding-left: 10px;
      color: #e6a309;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .dismiss-border {
      border-left: 1px solid #fae4b2;
    }
  }

  .card-img-visa {
    position: absolute;
    width: 70px;
    height: 45px;
    top: 35px;
    right: 15px;
  }

  .card-img-mc {
    position: absolute;
    width: 45px;
    height: 25px;
    top: 45px;
    right: 25px;
  }

  .expiry-input-div {
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: #e7effd;
    box-shadow: 0px 0px 2px #0000004d;
    text-align: center;
  }

  .expiry-input {
    max-width: 100px; 
    text-align: center;
    box-shadow: 0px 0px 0px !important;
  }

  .slash {
    width: 25px;
    text-align: center;
    color: gray;
  }

  .payment-method-text {
    font-size: $subtitle-font-size;
    font-weight: 700;
  }

  .br-2 {
    border-radius: 2px;
  }

  .wallet-row {
    .wallet-image {
      font-size: 2.5rem;
    }
    border: 1px solid rgb(220, 220, 220);
  }

  .payment-card {
    text-align: start;
    border: 1px solid rgb(220, 220, 220);
  }

  .input-wrapper {
    position: relative;

    .help-icon-ltr {
      font-size: 2rem;
      position: absolute;
      right: 10%;
      top: 25%;
      color: #e2e5f1;
    }
    .help-icon-rtl {
      font-size: 2rem;
      position: absolute;
      left: 10%;
      top: 25%;
      color: #e2e5f1;
    }
  }

  .payment-row {
    .title {
      font-size: $title-font-size;
    }

    .react-switch-bg {
      .react-switch-handle {
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    h2 {
      font-size: 1.875rem;
      font-weight: 700;
      color: $head-color;
    }

    .pay-with-alisuq-wallet {
      font-weight: 700;
      color: $head-color;
      margin-inline-start: 8px;
      .available-balance {
        color: #404553;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    button {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 48.5%;
      background-color: white;
      box-shadow: 0px 0px 2px #0000004d;
      border: 1px solid #003366;
      font-size: 1.109875rem;
      font-weight: 700;
      color: $head-color;

      svg {
        font-size: 1.109875rem;
        font-weight: 700;
        width: 27px;
        height: 21px;
        color: #38ae04;
        margin-inline-end: 8px;
      }
    }

    .payment-button {
      font-size: 1rem;
    }

    .card-button {
      svg.card-icon {
        width: 30px;
        height: 30px;
      }
    }

    .cash-button {
      svg.cash-icon {
        width: 30px;
        height: 30px;
      }
    }

    .gray-button {
      border: none;
      opacity: 0.6;

      svg {
        font-weight: 700;
        color: #000000;
        margin-inline-end: 8px;
      }
    }

    .order-button {
      border: none;
      // background-color: $form-btn-bg;
      color: white;
      height: 57px;
      min-height: 57px;
      max-height: 57px;
    }

    .active-btn {
      background-color: $form-btn-bg;
    }

    .inactive-btn {
      background-color: #cbcdcf;
    }

    label {
      color: #80879f;
      font-size: 1rem;
      font-weight: 700;
    }

    input {
      background: green;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #e7effd;
      padding: 14px 10px;
      box-shadow: 0px 0px 2px #0000004d;

      &::placeholder {
        height: 20px;
        line-height: 20px;
        padding-top: 5px;
      }
    }

    .with-border {
      border: 1px solid $border-color;
      box-shadow: none;

      &::placeholder {
        text-align: center;
      }
    }
  }

  .margin-bottom-4 {
    margin-bottom: 20px;
  }
  .cards-div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    height: auto;
    .radio-input {
      margin-left: 10px;
      display: flex;
      align-self: center;
      justify-content: center;
      background: #fff;
      .radio {
        width: 50px;
        height: 1.5em;
        background: #fff;
        box-shadow: none;
      }
      .input-group-text {
        border-radius: 100%;
      }
    }
    .img-and-label-input {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .img-and-label {
      }
      .security-number-payment-card {
        .sn-input {
          width: 90px;
          height: 40px;
          margin-right: 15px;
        }
      }
    }
  }
}
