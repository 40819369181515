@import "../mixins.scss";
@import "../variables.scss";

.offerrow{
  margin-top:30px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.shop-by-brands .swiper-wrapper {
  justify-content: center;
}

.all-page-container {
  max-width: 1440px !important;
  .row.about-and-contact-row.mx-auto.px-4 .col-md-4 {
    border: 1px solid #d4982b;
    border-right-width: thick;
    box-shadow: 1px 1px 0px 2px #d4982b;
    height: 301px;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    margin: 15px!important;
    background-color: #fff4e0;
}

.categories-swiper {
  .swiper-wrapper {
    justify-content: center;
  }
}

.sectiontitle-individualpage {
  margin-bottom: 0px;
  text-align: center;
  color: #003366;
  font-weight: 800;
  font-family: 'Futura Heavy';
  font-size: 28px;
}

.paintbrand {
  background-color: #fcfaf4;
  border: 1px solid #d4982b;
  border-radius: 100px;
}

  .title-row {
    display: flex;
    width: 100%;
    
    padding-top: 15px;
    span.uptostyle {
      font-family: Futura Heavy;
      color: #d9002a;
  }

    .title {
      display: block;
     font-family: 'Futura Heavy';
     font-size: 1.5rem;
      letter-spacing: 0.2px;
      text-align: left;
    
    }
    .viewalldiv{
      width: 30%;
      text-align: right;
    }
    .viewalldiv-ar{
      text-align: left !important;
    }
    .view-all-text {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      font-size: 0.916666rem;
      background-color: black;
      color: white;
      width: 30%;
    }
  }
}

  .offer-tabs {

  }
  ul.react-tabs__tab-list {
    background-color: #d4982b;
    text-align: center;
    padding: 2px;
}
.react-tabs__tab {
  border: unset;
  font-weight: 700;
  font-size: 22px;
}
.react-tabs__tab:focus {
  box-shadow: unset;
  border-color: unset;
  outline: none;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: unset;
}
.offerends{
  font-size: 13px;
}
.weeklysales{
  font-weight: 800;
  margin-bottom: 50px;
}
.react-tabs__tab-panel  {
  max-width: 1440px !important;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px;
}
.avcc {
  text-align: -webkit-center;
}
.react-tabs__tab {
  color: white;
}
.react-tabs__tab--selected{
  color:black;
}
