@import "../mixins.scss";

.help{
  text-align-last: left;
}
.bottom-footer.all-page-container.mx-auto {
  padding-top: 15px;
}
.helpfooter{
  background-color: #003366;
  padding: 8px 0px 8px 0px!important;
  align-items: center;
}
.helptex {
  margin-right: 10px;
  color: #ffffff;
  font-size: 14px;
}
.help{
  font-weight: 700;
  color: #ffffff;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  font-size: 23px;
}
.text-align-right {
  text-align-last: right;
}
.helpcolright{
margin: 0px!important;
}
.footer-pages-content {
  margin-top: 50px;
  text-align: center;
}
.footer-page-heading {
  text-transform: uppercase;
  color: #7d252a;
  font-weight: 600;
  margin-bottom: 40px;
}
.footer-page-subheading {
  color: #7d252a;
  font-weight: 600;
  margin-top: 40px;
}
.line {
  display: block;
  width: 100%;
  border-bottom: 1px dashed rgb(0, 0, 0);
  text-align: center;
}
.line h2 {
  font-size: 15px;
  text-align: center;
  position: relative;
  top: 10px;
  padding: 0 15px;
  display: inline-block;
  background: white;
  margin-bottom: 0px;
  margin-top: 0px;
}

.bulkcatimg {
  margin: 5px;
  border-radius: 10px;
}
.bulkcat {
  margin-top: 20px;
}
.pagesbanner {
  text-align: center;
}
.mycenter {
  text-align: center;
}
.ret {
  margin-top: 40px;
}
.blko {
  margin-bottom: 0px;
  margin-top: 30px !important;
}
