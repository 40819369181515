.ali-day {
    background-color: white;

    .section {
        padding: 30px 0px 30px 0px;

    }
    .neverwetdesk{
       margin-top: 5px;
    }
    .alidaysecname.grey{
        color: #555555;
        font-family: 'Futura Heavy';
        font-size: 38px;
        margin-bottom: 5px;
    }
    .alidaysecname {
        color: #003366;
        font-family: 'Futura Heavy';
        font-size: 38px;
        margin-bottom: 5px;
    }

    .emptyspacer {
        height: 15px;
    }

    .alidaysecdetails {
        font-family: 'Futura';
        font-size: 15px !important;
        letter-spacing: 0.2px;
    }

    .alidaysubtitle {
        font-family: 'Futura';
        font-size: 16px !important;
    }

    .catalog1 {
        padding-right: 0px;
    }

    .catalog2 {
        padding-right: 8px;
    }

    .catalog3 {
        padding-left: 8px;
    }

    .catalog4 {
        margin-bottom: 0px !important;
    }

    .catalogsection img {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;
    }


    .bg-light-theme {
        background-color: #d4982b21;
    }

    .herosec {

        button.quick-view-btn.btn.btn-secondary {
            padding-left: 30px;
            padding-right: 30px;
        }

        a {
            text-decoration: none;
        }

        h4 {
            color: #212529;
            font-family: 'Futura Heavy';
        }

        .herodesc {
            color: #212529;
            margin-top: 15px;
            font-weight: 500 !important;
        }

        .heroprice {
            margin-top: 15px;
        }

        .herofeatures {
            margin-bottom: 10px;

            p {
                text-decoration: none;
                color: #212529;
                margin: 0px !important;
            }
        }
    }

    .topicons {
        width: 30px;
        height: 30px;
    }

    .heroimagecol {
        text-align-last: center;
    }

    .combosec {
        a {
            text-decoration: none !important;
        }

        img {
            background-color: #f7e4ba;
            padding-left: 31px;
            padding-right: 31px;
        }

        .combocontent {
            background-color: #ddae52;

            .my-1 {
                margin-top: 0px !important;
            }
        }

        .comboprice {
            color: #003366;
            font-size: 20px;
            font-weight: 600;
            font-family: 'Futura Heavy';
            line-height: 0.5;
        }

        .comboname {
            color: #003366;
            font-size: 20px;
            font-weight: 600;
            text-decoration: none;
        }

        .col-md-3 {
            padding-right: 8px !important;
            padding-left: 8px !important;
        }
    }

    .sbcatsection {
        .combocontent {
            text-align: center;
            background-color: #ddae52;

            .my-1 {
                margin-top: 0px !important;
            }
        }
        .my-1 {
         
            line-height: 1!important;
        }
        .comboprice {
            color: #003366;
            font-size: 20px;
            font-weight: 600;
            font-family: 'Futura Heavy';
            line-height: 0.5;
        }

        .comboname {
            color: #003366;
            font-size: 20px!important;
            font-weight: 600;
            text-decoration: none;
        }

        .sbcswiper .swiper-slide.swiper-slide:before {
            content: "";
            position: absolute;
            top: -9px;
            left: 0px;
            width: 100%;
            height: 15px;
            background: linear-gradient(135deg, #fff 12px, transparent 0%), linear-gradient(-135deg, #fff 12px, transparent 0%);
            background-size: 18px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .evenslide {
            .sbc {
                background-color: #27aa88;

            }

            .combocontent {
                background-color: #add7cc;
                width: 150px;
                padding: 5px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .comboname {
                color: #107258;
                font-family: 'Futura Heavy';
            }

            .comboprice {
                color: #107258;
                font-family: 'Futura Heavy';
            }
        }

        .oddslide {
            a{
                text-decoration: none;
            }
            .sbc {
                background-color: #27aa88;

            }

            .combocontent {
                background-color: #add7cc;
                width: 150px;
                padding: 5px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .comboname {
                color: #107258;
                font-family: 'Futura Heavy';
            }

            .comboprice {
                color: #107258;
                font-family: 'Futura Heavy';
            }
        }

        .evenslide {
            a{
                text-decoration: none;
            }
            .sbc {
                background-color: #ff9a84;

            }

            .combocontent {
                background-color: #fbc0b4;
                width: 150px;
            }

            .comboname {
                color: #eb502f;
                font-family: 'Futura Heavy';
            }

            color: #eb502f;
            font-family: 'Futura Heavy';
        }
    }



button.quick-view-btn.btn.btn-secondary {
    margin-top: 15px;
    background-color: #003366;
    border: 1px solid #003366;
    border-radius: 100px;
}

.pagesbanner {
    padding-top: 15px;
    padding-bottom: 30px;
}

.paintcat {
    background-color: #f7e4ba;
    border: none;
    border-radius: 2px;
}
.brandfav img{
    border-radius: 100px;
    border: 2px solid #003366;
}
.paintbrand {
    background-color: #fcfaf4;
    border: 1px solid #d4982b;
    border-radius: 100px;
}

.topofferimg {
    width: 175px;
    -webkit-transform: rotate(362deg);
}
.catwelove .col-sm-4{
padding: 8px!important;
}

.offers.catbox {
    padding: 15px;
}

.six6.catsecpic img {
    border: 1px solid #9e9e9e52;
}

.surfacepat .product-list-desktop .product-list-container {

    // background-color: #fcfaf4;
}

.catrow {
    display: flex;

}

.six6 img {
    border: none;
}

.catbox {
    padding: 0px;
}

.offerbg {
    background-color: #ffffff;
    border-bottom: 140px solid #f8e89a;
    border-left: 23px solid transparent;
    border-right: 14px solid transparent;
    height: 0;
    border-radius: 50px;
    margin-top: 30px !important;
    margin: 15px;
    transform: rotate(2deg);
    -webkit-transform: rotate(358deg);
    -moz-transform: rotate(2deg);
    text-align: right;
}

.sectiontitle-individualpage {
    margin-bottom: 0px;
    text-align: center;
    color: #212529;
    font-weight: 600;
    font-size: 26px;
    font-family: 'Futura Heavy';
}

.titlewrap.noclr {
    background-color: #ffffff;
}

.offertitles {
    text-align: left;
    float: left;
    width: 170px;
    font-family: 'Futura heavy';
    margin-top: 29px;
    font-size: 35px;
    margin-left: 8PX;
    text-transform: uppercase;
    -webkit-transform: rotate(362deg);
    line-height: 0.9;
    /* -webkit-text-stroke: 1px white; */
    -webkit-text-stroke: thin;
}

span.offerprice {
    font-family: 'Futura heavy';
    margin-top: 29px;
    color: #ff5722;
}

.mypages-container {
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 1300px;
}

@media (min-width: 1360px) {
    .container {
        width: 1300px;
    }
}

@media (min-width: 1280px) {
    .container {
        width: 1220px;
    }
}

@media (min-width: 1170px) {
    .container {
        width: 1024px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
}