.thank-you-page-mobile {
  .thank-you-page-card-area {
    padding: 80px 0px 80px;
    border: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    .thankyou-head-text {
      font-size: 18px;
      font-weight: bold;
      color: #41455f;
      opacity: 1;
      line-height: 30px;
    }

    .thankyou-sub-text {
      display: flex;
      justify-content: center;
      line-height: 10px;
      .order-placed-text {
        font-size: 16px;

        color: #41455f;
        padding-top: 2px;
        opacity: 0.6;
        padding-right: 5px;
      }
      .order-placed-no {
        font-size: 18px;
        font-weight: bold;

        color: #41455f;
        padding-left: 5px;
        opacity: 1;
      }
    }
  }
  .thankyoupage-continueshopping-button {
    margin-top: 4px;
    background: #003366 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
  }
}
