@import "../../../mixins.scss";

.topbrand-head {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-top: 50px;
}

.brands-row.no-gutters img {
  background-color: white;
  border-radius: 100%;
  filter: drop-shadow(1px 1px 5px #cccccc76);
}

.brands-row.no-gutters .brands-title {
  display: none !important;
}

.item-main-desktop {
  .image-container {
    position: relative;
    text-align: center;
    color: white;

  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: bold;

    text-transform: uppercase;
    color: #fff !important;
    text-shadow: 1px 2px 5px black;
    display: block;
    width: 100%;
  }

  .offertabs-mob.col-12.col-sm-6.col-md-4.col-lg-3 {
    background-color: #003366;
    text-align-last: center;
    margin-right: 20px;
    max-width: 260px;
    padding: 10px;
  }

  .belowabove {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .offertabstxt-mob {
    color: white;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 0px;
  }

  .offerstop.image-container-mob {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px !important;
  }

  .emptyspace-20 {
    margin-top: 20px;
  }

  .top-img-mob {
    border-radius: 8px;
    max-width: 95px;
  }

  .top-img:hover {
    opacity: 0.7;

    .centered {
      display: block;
    }
  }

  .items-body {
    @include for-desktop-screen {
      margin-top: 50px;
      margin-bottom: 50px;
      justify-content: start;
    }

    @include for-mob-s4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill, 245px);
      gap: 0px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }

    @include for-mob-s3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill, 245px);
      gap: 0px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }

    @include for-mob-s2 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill, 245px);
      gap: 0px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }

    @include for-mob-s1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, 245px);
      column-gap: 20px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }

    @include for-md-1-screen {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-md-2-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-lg1-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-lg2-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-xl-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-xxl-screen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-template-rows: repeat(auto-fill, 455px);
      padding: 0px 5px 0px 15px;
      gap: 0px 8px;
    }

    @include for-mob-screen {
      margin-top: 10px;
      margin-bottom: 40px;
    }

    @include for-xl-1-screen {
      display: grid;
    }

    .pagination {
      direction: ltr;
    }

    .small-display-none {
      @include for-mob-screen {
        display: none;
      }
    }

    .large-display-none {
      min-height: 350px;

      @include for-desktop-screen {
        display: none;
      }
    }
  }

  .items-body-ar {
    @include for-md-1-screen {
      padding: 0px 15px 0px 0px !important;
    }

    @include for-md-2-screen {
      padding: 0px 15px 0px 0px !important;
    }

    @include for-lg1-screen {
      padding: 0px 15px 0px 0px !important;
    }

    @include for-lg2-screen {
      padding: 0px 15px 0px 0px !important;
    }

    @include for-xl-screen {
      padding: 0px 15px 0px 0px !important;
    }

    @include for-xxl-screen {
      padding: 0px 15px 0px 0px !important;
    }
  }

  .top-brands-mobile-view {
    .brands-row {
      display: flex;
      overflow-x: scroll;

      .col-width {
        margin: 5px;
        width: auto;

        .brand-logo {
          cursor: pointer;
          max-height: 70px;
        }

        .brands-title {
          cursor: pointer;
          margin: 0px;
          font-weight: bold;
        }
      }
    }
  }

  .top-brands-desktop-view {
    .brands-row {
      @include for-desktop-screen {
        margin-top: 10px;
        margin-bottom: 50px;
        justify-content: center;

        .brand-logo {
          max-height: 120px;
        }
      }

      @include for-lg2-screen {
        .brand-logo {
          max-height: 120px;
        }
      }

      @include for-md-2-screen {
        .brand-logo {
          max-height: 100px;
        }
      }

      @include for-md-1-screen {
        .brand-logo {
          max-height: 100px;
        }
      }

      .col-width {
        width: 13%;

        .brand-logo {
          cursor: pointer;
        }

        .brands-title {
          cursor: pointer;
          margin: 0px;
          font-weight: bold;
        }
      }
    }
  }
}