.coupon-card {
  border: 2px solid rgb(236, 235, 235);
  border-radius: 5px;
}

.coupon-card::before {
  left: -12px;
}

.coupon-card::after {
  right: -12px;
}

.coupon-card::after, .coupon-card::before{
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid rgb(226, 229, 241);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: rgb(255, 255, 255);
}

.coupon-code::before {
  left: -5px;
}

.coupon-code::after {
  right: -5px;
}
  
.coupon-code {
  border: 1px dashed #003366;
  color: #003366;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 14px;
  cursor: copy;
}

.coupon-code::after, .coupon-code::before{
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 1px dashed #003366;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: rgb(255, 255, 255);
}
  
.apply {
  background-color: #d3af44;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.card-text {
  margin: 0 !important;
  font-size: 14px !important;
}