.cart-empty-Page-desktop {
  .cartempty-card-area {
    border: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cart-head-text {
      font-size: 1.1rem;
      margin: 0px;
      color: #41455f;
      opacity: 1;
      line-height: 30px;
    }

    .cart-sub-text {
      text-align: center;
      font-size: 14px;
      color: rgb(0, 0, 0) !important;
      line-height: 30px;
    }

    .cart-continueshopping-button {
      margin-top: 10px;
      width: 250px;
      background: #003366 0% 0% no-repeat padding-box;
      border-radius: 4px;
      text-align: left;
      font-size: 0.8rem;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      padding: 12px 20px;
      text-decoration: none;
      border: none;
    }
  }
}
