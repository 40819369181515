@import "../mixins.scss";




.newproducthomepage {
  .emptyspacer {
    height: 30px;
  }
  .traderow{
    display: block;
    z-index: 999 !important;
    position: relative;
    text-align-last: center;
  }
.seamlesscol{
  align-self: center;
}
.seamlessimgs img{
  width: 600px;
}
.mt55{
  margin-top: 40px;
}
  .emptyspacer-small {
    height: 20px;
  }

  .personalized-recommendations {
    padding-bottom: 30px !important;
  }

  .testimonials {
    max-width: 1440px;
    text-align: center;
    margin: auto;
    padding: 50px 0px;

    .warehousetitle {
      font-family: 'Futura Heavy';
      color: #036;
      padding-top: 0px;
      font-size: 40px;
      padding-bottom: 10px;
      letter-spacing: 1px;
    }

    p {
      margin: 0px;
      font-family: 'Futura';
      font-size: 20px !important;
      line-height: 26px;
      letter-spacing: 1px;
    }
  }

  .empowering-business {
    max-width: 1440px;
    text-align: center;
    margin: auto;
    padding: 50px 0px;

    .warehousetitle {
      font-family: 'Futura Heavy';
      color: #036;
      padding-top: 0px;
      font-size: 40px;
      padding-bottom: 10px;
      letter-spacing: 1px;
    }

    p {
      margin: 0px;
      font-family: 'Futura';
      font-size: 20px !important;
      line-height: 26px;
      letter-spacing: 1px;
    }

    .frame {
      border-radius: 16px;
      display: flex;
      flex-direction: column-reverse;
      padding: 25px;
      position: relative;
      text-align: left;

.tradecontent{
  background-color: #003366cf;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

      .title {
        font-size: 18px;
        font-family: 'Futura Heavy';
        color: #D4982B;
      }

      .subtitle {
        font-size: 29px;
        font-weight: bold;
        color: white;
        font-family: 'Futura Heavy';
        letter-spacing: 0.5px;
        line-height: 30px;
      }
    }

    .mission-section {
      background: url('../../../assets/images/mission.webp');
      background-color: #949494;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 600px;
    }

    .globe-section {
      background: url('../../../assets/images/promise.png');
      background-color: #949494;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 290px;
      margin-bottom: 10px;
    }

    .technology-section {
      background: url('../../../assets/images/team.jpeg');
      background-color: #949494;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 290px;
      margin-top: 20px;
    }
  }

  .testimonal-swiper {
    .swiper-item {
      background-color: white;
      border-radius: 16px;
      padding: 100px;

      img {
        width: 180px;
      }
    }
  }

  .homepage-main-banner-swiper {
    max-width: $max-page-width;
    margin: 20px auto !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .aboutvidicon {
    width: 24px;
    filter: invert(77%) sepia(50%) saturate(2021%) hue-rotate(172deg) brightness(92%) contrast(192%);
  }

  .aboutvid {
    color: #003366;
    font-family: 'Futura';
    font-size: 20px;
    letter-spacing: 0.7px;
  }

  .aboutvidsec {
    margin-bottom: 20px;
  }

  .mainheadi {
    font-size: 40px;
    letter-spacing: 1px;
  }

  .custom-card-wholesale {
    background: url('../../../assets/images/homepage/warehouse1.jpeg');
    background-color: #949494;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    height: 460px;
    margin-right: 30px;
    text-align: center;
    padding-top: 20px;
    position: relative;
    width: 460px;

    h4 {
      color: rgb(255, 255, 255);
      font-family: 'Futura Heavy';
      padding: 10px;
      font-size: 20px;
      text-transform: uppercase;
      background-color: #003366cf
    }
  }

  .custom-card-wholesale2 {
    background: url('../../../assets/images/homepage/warehouse2.jpeg');
    background-color: #949494;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    height: 460px;
    margin-right: 30px;
    text-align: center;
    padding-top: 20px;
    position: relative;
    width: 460px;

    h4 {
      color: rgb(255, 255, 255);
      font-family: 'Futura Heavy';
      padding: 10px;
      font-size: 20px;
      text-transform: uppercase;
      background-color: #003366cf
    }
  }

  .custom-card-wholesale3 {
    background: url('../../../assets/images/homepage/warehouse3.jpeg');
    background-color: #949494;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    height: 460px;
    margin-right: 30px;
    text-align: center;
    padding-top: 20px;
    position: relative;
    width: 460px;

    h4 {
      color: rgb(255, 255, 255);
      font-family: 'Futura Heavy';
      padding: 10px;
      font-size: 20px;
      text-transform: uppercase;
      background-color: #003366cf
    }
  }

  .custom-card-trade {
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background: hsla(0, 0%, 100%, .11);
    border-radius: 20px;
    font-size: 16px;
    height: 396px;
    line-height: 22px;
    overflow: hidden;
    padding: 40px;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    h4 {
      color: white;
      padding: 18px;
      font-family: 'Futura Heavy';
      padding-top: 35px;
      font-size: 20px;
      text-transform: lowercase;


    }
  }

  .trade {
    padding-bottom: 50px !important;

    .tradetitle {
      font-size: 40px;
      font-family: 'Futura Heavy';
      text-align: left;
      letter-spacing: 1px;
      padding-top: 20px;
      padding-bottom: 40px;
    }

    .title {
      text-align: left !important;
      text-transform: capitalize;
      margin-bottom: unset !important;
      padding: unset;
    }

    .tradeicon {
      display: block;
      height: 70px;
      margin: 18px 0 20px;
      width: 400px;
    }

    span.tradedesc {
      font-family: 'Futura';
      letter-spacing: 0.8px;
    }

    .tradevidbtn {
      display: block;
      color: white;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-margin-end: 20px;
      -webkit-align-items: center;
      align-items: center;
      background-color: #00000032;
      border: 1px solid #fff;
      border-radius: 999px;
      cursor: pointer;
      font-weight: 600;
      height: 48px;
      line-height: 48px;
      margin-inline-end: 20px;
      max-width: 320px;
      padding: 0 20px;
      letter-spacing: 1px;
      margin-top: 30px;
    }

    .aboutvidicon {
      margin-right: 5px;
    }

  }

  .hidde {
    display: none !important;
  }

  .our-warehouse {
    background-color: white;
  }

  .emptyspacer {
    height: 20px;
    background-color: white;
  }


  h2.warehousetitle {
    font-family: 'Futura Heavy';
    color: #036;
    padding-top: 50px;
    padding-bottom: 30px;
    font-weight: 900;
    letter-spacing: 0.5px;
  }

  .row.align-items-center.brands-lg-section {
    padding-bottom: 30px;
    background-color: white;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 30px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .category-content {
    background-color: #f8f8f8;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    text-align: justify;

    p {
      font-size: 14px !important;
    }

    li {
      font-size: 14px !important;
    }

    h2 {
      text-align: left;
    }
  }

  .read-more-link {
    display: block;
    text-align: center;
    color: black;
    letter-spacing: 0.3px;
    font-weight: 800;
    font-size: 14px !important;
    cursor: pointer;
  }


  .catsecpic img {
    border: 1px solid #9e9e9e52;
  }

  .six6 img {
    border: 1px solid #9e9e9e52;
  }

  .brands-row {
    display: flex;
    overflow-x: scroll;
  }

  .emptyspacer-big {
    height: 60px;
  }

  .imgofr-desk {
    background-color: #d9002a;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    margin-bottom: 5px;
  }

  .imgofr-desk h4 {
    font-size: 16px;
    text-align: center;
    padding-top: 30px;
    color: white;
    padding-bottom: 40px;
    font-weight: 800;
    font-family: 'Futura Heavy';
    animation: blinker 1s step-start infinite;
    text-transform: uppercase;
  }

  span.ofrtxt {
    display: block;
    font-size: 17px;
    color: black !important;
    font-weight: 900;
    text-align: center;
    font-family: 'Futura Heavy';
    letter-spacing: 0px;
  }

  .imgofr-desk span {
    display: block;
    font-size: 10px;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .one1 {
    position: relative;
  }

  .two2 {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0px 15px;
  }

  .three3 {
    padding: 0px 15px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }

  .four4 {
    flex: 0 0 calc(14.2857% - 7.5px);
    padding-bottom: 0px;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
  }

  .six6 {
    position: absolute;
    inset: 0px;
    display: flex;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
  }

  .six6 img {
    display: block;
    max-width: 100%;
  }

  .catsecbtn {
    // float: right;
  }

  .catsec {
    display: unset;
  }

  p.section-subtitle {
    display: block;
    font-weight: 600;
    letter-spacing: 0.7px;
  }

  img.homeofferimg {
    width: -webkit-fill-available;
  }

  .mic,
  .mid {
    margin-bottom: 30px;
  }

  .featuredcat {
    // padding-left: 50px;
    // padding-right: 50px;
  }

  h2.section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // display: block;
    font-size: 22px;
    font-family: 'Futura Heavy';
    font-weight: 700;
  }


  h2.stageheadingbk {
    display: block;
    align-items: center;
    justify-content: space-between;
    font-size: 33px;
    background: linear-gradient(90deg, #003366 0%, #D4982B 15.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Futura Heavy';
    font-weight: 700;
  }

  span.stageheading {
    display: block;
    align-items: center;
    justify-content: space-between;
    font-size: 33px;
    background: linear-gradient(90deg, #003366 0%, #D4982B 15.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Futura Heavy';
    font-weight: 700;
  }


  .brands-section img {
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(229, 229, 229);
  }

  h3.section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // display: block;
    font-size: 30px;
    background: linear-gradient(90deg, #003366 0%, #D4982B 15.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Futura Heavy';
    font-weight: 700;
  }

  .whitecard {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    padding-bottom: 30px;

    .sectiontitle-container-left {
      // padding-left: 20px;
      // padding-right: 20px;
    }

    .brands-section {

      // padding-left: 20px;
      // padding-right: 20px;
      .sectiontitle-container-left {
        padding-left: unset;
        padding-right: unset;
      }

      .swiper-slide {
        margin-right: 18px !important;
      }
    }

    .product-list-desktop .swiper-container {
      // padding-left: 20px;
      // padding-right: 20px;
    }

    .all-categories-section .sectiontitle-container-left {
      padding-left: unset;
      padding-right: unset;
    }


    .all-categories-section .col-md-3 {
      padding-right: 0px;
    }

    .all-categories-section {
      padding-right: 20px;
    }
  }

  .whitecard1 {
    background-color: white;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    padding-bottom: 30px;
  }

  .section-underline {
    position: relative;
    z-index: 1;
  }

  .sectiontitle-container-left {
    text-align: left;
    // margin: 0px 10px 0px 10px;
  }

  .catsecpic {
    padding-left: 5px;
    padding-right: 5px;
  }

  .sectiontitle-container {
    text-align: center;
  }

  .middleoffershide {
    display: none !important;
  }

  .section-underline:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 2px;
    width: calc(100% + 4px);
    height: 8px;
    background: #d4982b;
    border-radius: 10px;
    z-index: -1;
    transform: translate(-50%);
  }

  .nav-tabs {
    border: none !important;
  }

  .nav-link.active {
    border: 1px solid black !important;
    background-color: unset !important;
    font-weight: 600 !important;
    border-radius: 100px !important;
    padding: 0.5rem 1rem !important;
  }


  .ViewAllbtn {
    text-align: center;
    font-weight: 400;
  }

  .ViewAllbtn a {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: white;
    background-color: #003366;
    font-size: 1rem;
    // border: 1px solid #212529;
    // color: #212529;
  }

  // .ViewAllbtn a:hover {
  //   border: 2px solid #212529;
  //   text-decoration: none;
  //   color: #212529;

  // }

  .temphide {
    display: none !important;
  }

  .kid-fashion-banner-row.mx-auto.last-bannernew.row {
    display: none;
  }

  .top-category-header span {
    position: relative;
    z-index: 1;
  }


  // .modal-backdrop{
  //   z-index: 99999 !important;
  // }

  .offers-modal {
    z-index: 99999 !important;

    .modal-dialog {
      margin-top: 70px;
    }

    .modal-dialog-centered {
      margin: auto !important;
    }

    .modal-content {
      border: none;
      background-color: transparent;
    }
  }

  .product-home-page-desktop {
    .home-banner {
      position: relative;
      background: url('../../../assets/images/homepage/homebanner-final.png');

      padding: 20px;
      height: 400px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
 

      .banner-content {
        margin-top: 80px;

        .searched-text {
          color: white;
          font-size: 40px !important;
        }
      }

      h1 {
        font-family: "Futura Heavy", sans-serif;
        color: #003366;
        font-weight: bold;
      }

      .search-form {
        display: flex;
        margin-top: 30px;
        width: 700px;
        position: relative;

        .form-control {
          color: #003366;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #003366!important;
          box-shadow: none !important;
          height: 56px;
          padding-left: 20px !important;
          padding: 12px;

     
        }
        .form-control::placeholder {
          color: #003366;
          opacity: 1; /* Ensure it's fully visible */
      }
        .search-box {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -159px;

          .search-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            border: 1px solid #003366;
            background-color: #003366;
            color: white;
            height: 49px;
            width: 125px;
            padding: 0px 11px;

            span {
              font-size: 17px;
              font-family: 'Futura';
              letter-spacing: 1px;
            }
          }
        }
      }
    }

    // .home-banner::before {
    //   content: '';
    //   /* This is required to generate the pseudo-element */
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: #00000080;
    //   opacity: 0.8;
    //   /* Black overlay with 50% opacity */
    //   z-index: 1;
    //   /* Ensure the overlay is above the background but below content */
    // }

    .home-banner>* {
      position: relative;
      /* Ensure other content inside the banner appears above the overlay */
      z-index: 2;
    }

    .middle-cards-row {
      background-color: #003366;
      padding-top: 40px;
      padding-bottom: 40px;

      // margin: 15px;
      // margin-top: 0px;
      // margin-bottom: 0px;
      .custom-card:hover {
        background-color: #254670a1;
        border: none !important;
      }

      .col-md-3 {
        padding-left: 25px;
        padding-right: 25px;
      }

      .custom-card {
        border-radius: 20px;
        cursor: pointer;
        background-color: #254670;
        box-shadow: none;
        color: white;
        padding: 25px 20px;

        .icon-div {
          margin-right: 10px;
          float: left;
          border-radius: 50px;
          width: fit-content;
          padding: 10px;
          background-color: #3b597e;
          overflow: hidden;

          .engineer-icon {
            width: 50px;
            height: 50px;
          }
        }

        .title {
          margin-top: 10px;
          font-family: "Futura Heavy", sans-serif;
          font-weight: 600;
          font-size: 18px;
        }

        .content {
          font-size: 15px !important;
          line-height: 20px;
        }
      }
    }

    .divider {
      height: 15px;
      background: linear-gradient(270deg, #E4D195 -23.33%, #FFEEAA -12.33%, #C8A860 31.67%, #B08535 50%);
    }

    .middle-section {
      background-color: #F7F2F0;
      padding-top: 30px;
      padding-bottom: 30px;

      .col-md-3,
      .col-md-5,
      .col-md-4 {
        padding: 25px 8px;
      }

      .auth {
        background-color: white;
        border-radius: 20px;
        padding: 20px 0px;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          text-align: center;
          margin: 10px 0px;
          font-size: 15px !important;
        }

        button {
          border-radius: 100px;
          border: none;
          font-weight: bold;
          border: 1px solid #003366;
          font-family: 'Futura Heavy';
          background-color: white;
          color: #003366;
          width: 110px;
          padding: 9px;
        }

        .active {
          background-color: #003366;
          color: white;
        }
      }

      .get-app {
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        height: 67%;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          width: 100%;
        }

        h5 {
          font-weight: bold;
          color: #003366;
          font-family: "Futura Heavy", sans-serif;
        }

        .get-app-img {
          height: 130px;
        }

        .googleplay-img {
          height: 40px;
        }
      }

      .banners-section {
        background-color: white;
        border-radius: 20px;
        padding: 15px;

        img {
          width: 100%;
          // height: auto;
          margin-bottom: 10px;
          border-radius: 10px;
        }

        .last-img {
          margin-bottom: 0px;
        }
      }

      .reasons-to-shop-section {
        background-color: white;
        border-radius: 20px;
        padding: 20px 15px;
        height: 100%;

        h5 {
          font-family: "Futura Heavy", sans-serif;
          color: #003366;
          font-weight: bold;
        }

        .row {
          height: 97%;

          .left-col {
            padding-right: 7.5px;
            margin-bottom: 15px;
          }

          .right-col {
            padding-left: 7.5px;
            margin-bottom: 15px;
          }

          .card {
            border-radius: 10px;
            background-color: #F5F4F4;
            border: none;

            img {

              height: 202px !important;
              width: 269px !important;
              border-radius: 10px;

            }
          }
        }
      }
    }

    .discover-building-solutions {
      background-color: white;
      padding: 80px 20px 50px 20px;

      h2 {
        font-weight: bold;
        color: #036;
        font-size: 55px;
        letter-spacing: 0.5px;
        font-family: "Futura Heavy", sans-serif;
      }

      .count-div {
        border-left: 5px solid #DDDDDD;

        .count {
          font-weight: 600;
          color: #D4982B;
          font-family: 'Futura Heavy';
          letter-spacing: 0.8px;

          font-size: 40px;
        }

        p {
          font-size: 20px !important;
          letter-spacing: 1px;
          color: #036;
        }
      }
    }

    .categories-section {
      background-color: white;
      padding: 0px 20px 50px 20px;

      img {
        height: 170px;
        width: auto;
      }

      .img-category {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #F5F4F4;
        border: 1px solid #CCCCCC;

        &:hover {
          border: 2px solid #D4982B !important
        }
      }
    }

    .streamline-ordering {
      background-color: white;
      padding: 50px 20px 50px 20px;

      h2 {
        font-weight: bold;
        color: #036;
        font-size: 40px;
        letter-spacing: 0.5px;
        font-family: "Futura Heavy", sans-serif;
        margin: 0px;
      }

      .icon-parent {
        width: 79px;
      }

      .cursor-pointer {
        cursor: pointer;
      }

      .active-icon {
        background-color: #ffefe5;
        border-width: 0;
        box-shadow: 0 0 0 6px #fff;
        // transform: scale(1.333);
        height: fit-content;
        padding: 7px;
        border-radius: 50%;
        width: fit-content;

        img {
          height: 52px;
          width: 52px;
        }
      }

      .icon {
        border: 2px solid #d8d8d8;
        height: fit-content;
        padding: 7px;
        border-radius: 50%;
        width: fit-content;

        img {
          height: 40px;
          width: 40px;
        }
      }

      .title {
        font-size: 28px !important;
        line-height: 34px;
        letter-spacing: 0.6px;
        margin-top: 13px;
        max-width: 568px;
        margin-top: 0px;
      }

      .active-title {
        color: #742013;
        font-size: 34px !important;
        font-family: 'Futura Heavy';
        font-weight: 600;
        line-height: 44px;
        margin-top: -11px;
        margin-bottom: 10px;
      }

      .subtitle {
        color: #767676;
        font-size: 16px !important;
        line-height: 22px;
        margin-top: 12px;
        max-width: 521px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }
    }

    .onboarding {
      position: relative;
      // background-color: #00336699;
      // background-image: url("../../images/homepage/onboarding.png");

      color: white;

      .title {
        font-weight: bold;
        text-align: center;
        margin-bottom: 35px;
        font-family: "Futura Heavy", sans-serif;
      }

      h3 {
        font-weight: bold;
        margin: 0px;
        font-family: "Futura Heavy", sans-serif;
      }

      ul {
        margin-top: 115px;
        padding-left: 20px;
        list-style: "✓  ";

        li {
          font-size: 15px;
        }
      }

      .data {
        width: fit-content;
        margin-left: auto;
      }

      .step {
        width: 70px;
        height: 70px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        p {
          margin: 0px;
          font-size: 30px !important;
          color: #003366;
          font-family: 'Futura Heavy';
        }
      }

      p {
        font-size: 14px !important;
        margin: 0px;
        text-align: center;
      }

      .lines {
        text-align: center;
        justify-items: anchor-center;

        p {
          margin: 0px;
          line-height: 16px;
        }
      }
    }

    .onboarding::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #003366;
      opacity: 0.9;
    }

    .personalized-recommendations {
      text-align: center;
      background-color: white;
      padding-top: 70px;
      padding-bottom: 90px;

      h3 {
        font-weight: bold;
        margin-bottom: 15px;
        font-family: "Futura Heavy", sans-serif;
      }

      .register-btn {
        background-color: #003366;
        color: white;
        cursor: pointer;
        padding: 7px 25px;
        border-radius: 20px;
        text-decoration: none;
        letter-spacing: 01px;
      }

      a {
        color: black;
      }
    }

    .all-categories-section {
      .category-view {
        margin-top: 10px;
        border: 1px solid #cccccb;
        border-radius: 5px;
        padding: 15px 15px;
        align-items: center;

        p {
          margin: 0px;
          color: #212529;
          text-decoration: none;
          font-family: 'Futura Heavy';

        }

        &:hover {
          border: 1px solid #212529;

          p {
            text-decoration: underline;
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      border-radius: 50%;

      height: 40px;
      width: 40px;
      color: black;
    }

    .swiper-button-disabled {
      display: none;
    }

    .swiper-button-next:after,
    .swiper-button-prev::after {
      font-size: 1.5rem;
      color: rgb(0, 0, 0);
    }

    max-width: 100%;
    // background-color: #e3e6e6bf;

    .kid-fashion-banner-row.mx-auto.row:last-child img {
      height: 100px;
    }

    .kid-fashion-banner-row.mx-auto.row:last-child {
      height: 100px;
    }

    .new-arrivals .product-price-container {
      display: none !important;
    }

    .new-arrivals .product-rating-row {
      display: none !important;
    }

    .content-wrapper {
      margin: auto;
      width: 100%;
      max-width: $max-page-width;

      .top-category-header {
        font-weight: 700;
        display: block;
        width: 100%;
        font-size: 32px;
        text-align: center;
        color: #7d252a;
      }


      .swiper-container {}

      .product-category-list-row {
        margin-bottom: 50px;
      }

      .working-row {
        margin: 50px 0;
        display: none;
      }

      .stageheading {

        .title1 {
          font-family: 'Futura Heavy';
          padding-top: 20px;
        }

        .title2 {
          background: linear-gradient(88deg, #003366 0%, #D4982B 60%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: 'Futura Heavy';
        }
      }

      .brands-lg-section {
        h2 {
          font-family: 'Futura Heavy';
        }

        .title2 {
          background: linear-gradient(90deg, #003366 0%, #D4982B 10%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        p {
          font-size: 15px !important
        }

        .view-brands-btn {
          background-color: #003366;
          color: white;
          padding: 10px 15px;
          border-radius: 5px;
          font-size: 15px;
          text-decoration: none;
        }

        .shop-now-btn {
          border: 1px solid #484848;
          color: #484848;
          padding: 10px 15px;
          border-radius: 5px;
          font-size: 15px;
          text-decoration: none;
        }

        .brand-img {
          border: 1px solid #777777;
          border-radius: 50%;
        }
      }

      .products-section {
        position: relative;
        // padding-left: 10px;
        // padding-right: 10px;
        box-sizing: border-box;
      }

      .product-list-row {
        // margin-bottom: 50px;
        max-width: 1440px;
        justify-content: center;
        margin-top: 15px;

        @include for-768-screen {
          margin-bottom: 0px;
        }

        .header {
          margin-bottom: 0px;
          font-weight: 700;
          color: #7d252a;
          opacity: 1;
          text-transform: capitalize;
          width: 100%;
          padding: 20px 0 0 0px;
          font-size: 27px;
          text-align: left;
        }
      }

      .kid-fashion-banner-row {

        max-width: 1440px;
        height: 300px;

        .kid-fashion-banner-container {
          width: 100%;
        }
      }

      .two-banners-row {
        margin-bottom: 50px;
        max-width: 1440px;
        height: 300px;
        margin-top: 70px;

        .two-banners-container {
          width: 100%;

          .banner-inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            column-gap: 10px;
          }
        }
      }
    }
  }
}