.product-home-page-desktop.b2bhome {
    background-color: #eff5fc;
}

.b2bhome {
    .product-list-container {
        background-color: #eff5fc;
    }

    .productembedimg {
        border-radius: 10px;
        height: 412px;
    }

    .b2bfullbanner {
        margin-top: 30px;
        border-radius: 15px;
    }

    .b2bfullbanner img {
        border-radius: 10px;
    }

    .b2bslide {
        width: unset !important;
    }

    .b2bslide img {
        height: 460px;
        border-radius: 10px;
    }

    .b2bslider {
        border-radius: 10px;
    }

    .b2bmainbanner {
        border-radius: 10px;

    }

    .b2bmainrow {
        margin-top: 20px;
    }

    .b2bmaincol {
        padding-left: 25px;
        padding-right: 10px;
    }

    .bg5 {
        width: 327px;
        height: 163px;
    }

    .bg6 {
        width: 327px;
        height: 163px;
    }

    .bg4 {
        margin-bottom: 20x;
        height: 277px;
        width: 675px;
    }

    .b2bmaincol1 {
        padding-left: 0px;
        padding-right: 00px;
    }

    .b2bmaincol2 {
        padding-left: 00px;
        padding-right: 0px;
    }

    .b2bmainrow2 {
        padding-top: 20px;
    }

    .mypages-container {
        max-width: 1400px;
        margin: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .sectiontitle-individualpage {
        font-weight: 600;
        font-size: 26px;
        font-family: sans-serif;
    }

    .shopbyc img {
        padding: 20px;
    }

    .shopbyc .swiper-slide {
        border-radius: 10px;
        background-color: white;
        width: 145px !important;


    }


    .emptyspacer-20 {
        height: 20px;
    }

    .b2b-products {
        .swiper-slide {
            padding-left: unset !important;
        }

        .product-card-container-desktop {
            border-radius: 10px;
        }

        .swiper-wrapper {
            gap: 20px;

        }

        .titlewrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        a.moreproducts {
            background-color: #f3e0bf;
            box-shadow: 0 3px 20px #0000000b;
            padding-left: 15px;
            border-radius: 100px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #003366;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }
}