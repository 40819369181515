.variants-modal {
    z-index: 99999 !important;
.vrpop{
    width: 107px!important;
}
    .modal-dialog {
        .modal-content {
            .modal-body {
                .info {
                    padding-top: 20px !important;
                    padding-bottom: 20px !important;

                    .product-title {
                        line-height: 30px;
                        font-size: 24px !important;
                        font-weight: 600;
                        color: #404553;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }

                    .sku {
                        color: #777;
                        font-size: 13px !important;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }

                    .rating-style {
                        background-color: #003366;
                        font-size: 12px;
                        color: #fff;
                        padding: 2px 12px;
                        border-radius: 3px;
                    }

                    .rating-count {
                        font-size: 12px;
                        margin-inline-start: 12px;
                        color: #3866df;
                        padding: 2px 0px;
                        border-bottom: 1px solid #3866df;
                        cursor: pointer;
                    }

                    .price-style {
                        color: #000000;
                        font-size: 22px !important;
                        font-family: "Futura Heavy";
                    }

                    .old-price {
                        margin-left: 10px;
                        font-size: 13px;
                    }

                    .inclusive-style {
                        color: #777;
                        font-size: 13px;
                        padding-inline-start: 50px;
                        letter-spacing: 0.3px;
                    }

                    .saving-style {
                        color: #777;
                        font-size: 14px;
                        margin-left: 5px !important;
                    }

                    .off-perc-style {
                        background-color: #b12704 !important;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 2px;
                        margin-left: 12px;
                        font-weight: 500;
                        padding: 2px 4px;
                    }

                    .variatspace {
                        margin-bottom: 7px !important;

                        .variation-text {
                            color: #777;
                            font-size: 14px;
                            letter-spacing: .3px;

                            strong {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                font-weight: 600;
                                color: #000;
                            }
                        }
                    }

                    .city-cont {
                        margin-bottom: 20px;
                        font-size: 12px;
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        flex-wrap: wrap;

                        .selected-span {
                            border: 2px solid #003366;
                            border-radius: 4px;
                            text-align: center;
                            vertical-align: initial;
                            background-color: #fff;
                            -webkit-margin-end: 10px;
                            margin-inline-end: 10px;
                            max-width: 100%;
                            width: 100px;
                            height: 100px;
                            padding: 5px !important;
                        }

                        .simple-span {
                            cursor: pointer;
                            border: 1px solid #8080809e;
                            text-align: center;
                            vertical-align: baseline;
                            border-radius: 4px;
                            background-color: white;
                            margin-inline-end: 10px;
                            max-width: 100%;
                            width: 100px;
                            height: 100px;
                            padding: 5px !important;
                        }
                    }

                    .quantity-cont {
                        .quantity-body {
                            .dropdown-cont {
                                .dropdown-outer {
                                    width: 90%;

                                    .Dropdown-control {
                                        height: 100%;

                                        .dropdownMenu {
                                            color: #404553;
                                            font-size: 14px;
                                            border-left: 1px solid #404553;
                                        }

                                        .dropdownPlaceholder {
                                            color: #404553;
                                            font-size: 14px;
                                        }
                                    }

                                    .Dropdown-arrow {
                                        top: 14px;
                                    }
                                }
                            }

                            .add-to-cont {
                                margin: 10px auto auto;
                                width: 100%;
                                height: 40px;
                                background-color: #003366;
                                border-radius: 3px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                .add-to-cart-text {
                                    color: #fff;
                                    font-size: 14px;
                                    font-weight: 700;
                                }
                            }

                            .get-inquiry-btn {
                                width: 100%;
                                margin-left: 2%;
                                height: 45px;
                                padding: 0px 10px;
                                background-color: #4dc247;
                                border-radius: 3px;
                                display: flex;
                                // justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                text-decoration: none;

                                .add-to-cart-text {
                                    p {
                                        color: white;
                                        font-size: 15px !important;
                                        font-weight: 500;
                                        letter-spacing: 0.3px;
                                    }
                                }
                            }

                            .out-of-stock {
                                width: 100%;
                                height: 45px;
                                margin-left: 2%;
                                background-color: #00000029;
                                border-radius: 3px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                .add-to-cart-text {
                                    color: white;
                                    font-size: 14px;
                                    font-weight: bold;
                                }
                            }
                        }

                        .buy-now-btn {
                            width: 100%;
                            margin: 2% auto auto;
                            height: 40px;
                            background-color: #d4982b;
                            border-radius: 3px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            .buy-now-text {
                                color: #fff;
                                font-size: 14px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}