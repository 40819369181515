.with-top-bar-and-bottom-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  $nav-bar-height: 55px;

  .app-bar {
    position: absolute;
    width: 100%;
    top: 0;
    height: $nav-bar-height;
  }

  .page-content {
    position: absolute;
    top: $nav-bar-height;
    left: 0;
    right: 0;
    bottom: $nav-bar-height;
    overflow: auto;
    height: calc(100vh - 110px);
    padding-bottom: 65px;
  }

  .bottom-nav {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: $nav-bar-height;
    border-top: 1px solid #00336617;
  }
}

.with-top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  $nav-bar-height: 55px;
  background-color: #fff;

  .app-bar {
    position: fixed;
    width: 100%;
    top: 0;
    height: $nav-bar-height;
    z-index: 1000;
  }

  .page-content {
    position: absolute;
    top: $nav-bar-height;
    left: 0;
    right: 0;
    height: calc(100vh - 55px);
    padding-bottom: 65px;
  }
}

.with-bottom-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  $nav-bar-height: 55px;

  .page-content {
    position: absolute;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: $nav-bar-height;
    overflow: auto;
    height: calc(100vh - 55px);
    padding-bottom: 65px;
  }

  .bottom-nav {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1;
    height: $nav-bar-height;
    border-top: 1px solid #00336617;
  }
}

.without-top-bar-and-bottom-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  $nav-bar-height: 55px;

  .page-content {
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: #fff;
    overflow: auto;
    padding-bottom: 65px;
  }
}
