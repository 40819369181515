@import "../mixins";

* {
  box-sizing: border-box;
}
.product-rating-row.mx-auto.row {
  display: none;
}
.seller-info-row.mx-auto.row {
  display: none;
}
// .product-card-container-desktop:hover {
//   -ms-transform: scale(0.91);
//   -webkit-transform: scale(0.91);
//   transform: scale(0.91);
// }
.wholesale-product-card-container-desktop {
  border-radius: 10px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  cursor: pointer;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 2px;
  transition: transform 1s;
  @include for-mob-screen {
    display: none;
  }
  .product-badge-row {
    .w-0 {
      width: 0px !important;
    }
    .discount-box {
      margin-right: auto;
      margin-left: 10px;
      background-color: #ee0000;
      height: 20px;
      width: 60px;
      text-align: center;
      color: white;
      border-radius: 3px;
      margin-top: 15px;
    }
    .discount-box-ar {
      margin-right: 10px;
      margin-left: auto;
      background-color: #ee0000;
      height: 20px;
      width: 60px;
      text-align: center;
      color: white;
      border-radius: 3px;
      margin-top: 15px;
    }
    @include flex(row, nowrap, space-between, flex-start);
    @include for-mob-screen {
      justify-content: flex-end !important;
    }
    width: 100%;
    .badge-container {
      width: 35%;
    }
    .like-button-container {
      background-color: rgba(245, 245, 245, 0.8);
      border-radius: 100%;
      @include width-height-lheight-textalign(30px, 30px, 30px, center);
      margin-inline-end: 8px;
      margin-top: 8px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .product-image-row {
    width: 100%;
    height: 220px;
    max-height: 220px;

    .img {
      height: 100%;
      width: auto;
      max-width: 100%;
      line-height: initial;
      text-align: center;
    }
  }

  .product-description-row {
    @include width-and-margin-bottom(85%, 5px);

    .coupon-row {
      .coupon-box {
        border-radius: 3px;
        background: #003366;
        color: white;
  
        p {
          font-size: 13px !important;
        }
      }
    }
    
    .product-promo-container {
      border: 1px solid $blue-header;
      opacity: 1;
      width: 100%;
      margin-bottom: 10px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include for-mob-screen {
        margin-top: 5px;
      }

      .product-promo {
        text-align: center;
        font-size: 0.625rem;
        color: $blue-header;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .product-description-container {
      width: 180px;
      margin-bottom: 10px;
      color: black!important;
      .product-description {
        text-align: start;
        font-size: 0.875rem;
        width: 100%;
        color: $head-color;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: auto;
        max-height: 43px;
        min-height: 43px;

        @include for-mob-screen {
          font-size: 13px;
        }
      }
    }

    .product-price-container {
      width: 96%;
      margin: auto;

      @include flex(row, wrap, flex-start, center);

      .regular-price {
        color: black;

        .currency {
          font-size: 1.28571rem;

          @include for-mob-screen {
            font-size: 14px;
            font-weight: 600;
          }
        }

        .value {
          font-size: 1.28571rem;
          font-weight: bold;

          opacity: 1;
          @include for-mob-screen {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .price-after-discount {
        text-align: left;
        margin-inline-end: 15px;

        .currency {
          font-size: 1.28571rem;
          color: #ee0000;

          @include for-mob-screen {
            font-size: 14px;
            font-weight: 600;
          }
        }

        .value {
          font-size: 1.28571rem;
          color: #ee0000;
          font-weight: bold;

          opacity: 1;
          @include for-mob-screen {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .price-before-discount {
        text-align: left;
        text-decoration: line-through;
        font-size: 0.857143rem;
        color: #7e859b;
      }
    }
  }

  .product-rating-row {
    margin-top: -5px;

    @include for-mob-screen {
      display: none;
    }

    @include width-and-margin-bottom(85%, 5px);

    .product-rating-container {
      width: 100%;

      .rating {
      }

      .product-rating-count {
        height: 12px;
        line-height: 12px;
        font-size: 10px;
        margin-top: 3px;
        color: #cc8e2a;
        margin-inline-start: 8px;
      }
    }
  }

  .seller-info-row {
    @include width-and-margin-bottom(85%, 0px);

    @include for-mob-screen {
      width: 90% !important;
      margin-left: auto !important;
      margin-right: auto !important;
      position: relative;
    }

    .seller-info-container {
      @include flex(row, nowrap, center, center);

      .seller-logo {
        width: 85px;
        height: 25px;
        margin-inline-end: 10px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .seller-verified {
        @include flex(row, nowrap, center, center);
        @include width-height-lheight-textalign(13px, 13px, initial, center);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        @include for-mob-screen {
          display: none;
        }

        svg {
          @include width-height-lheight-textalign(8px, 8px, initial, center);
          color: #cc8e2a;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .rating-mobile-cont {
    @include for-desktop-screen {
      display: none !important;
    }

    position: absolute;
    right: 0;
    .rating-mobile-value {
      color: rgb(245, 165, 35);
      font-size: 12px;
      font-weight: bold;
      margin-inline-start: 2px;
      margin-inline-end: 4px;
      margin-bottom: -2px;
    }
    .rating-mobile-count {
      color: #b2b8ca;
      font-size: 12px;
    }
  }

  .quick-view-row {
    padding: 10px;
  }

  .quick-view-btn {
    background-color: #003366;
    border: 1px solid #003366;
    border-radius: 100px;
  }

  .inquire-now-btn {
    display: block;
    color: white;
    // border: 1px solid #4dc247;
    border-radius: 25px;
    width: fit-content;
    background-color: #4dc247;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 6px 12px;
    text-decoration: none;
  }
}
