@import "../../../mixins.scss";
@import "../../../variables.scss";

.home-mob {
  max-width: 768px !important;
  margin: auto;
  min-height: 56px;


  @media only screen and (max-width: 344px) {
    .brands-section .swiper-slide {
      margin-right: 10px !important;
    }
  }
  

  .brands-lg-section {
    // margin-left: 10px !important;
    // margin-right: 10px !important;
    padding-left: unset!important;
    padding-right: unset!important;
    border-radius: unset!important;

    h2 {
      font-family: 'Futura Heavy';
      font-size: 26px;
      line-height: 1;
    }

    .title2 {

    }

    p {
      font-size: 15px !important
    }

    .view-brands-btn {
      background-color: #003366;
      color: white;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 15px;
      text-decoration: none;
    }


    .shop-now-btn {
      border: 1px solid #484848;
      color: #484848;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 15px;
      text-decoration: none;
    }

    .brand-img {
      border: 1px solid #777777;
      border-radius: 50%;
    }
  }

  .whitecard{
    background-color: white;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: unset;
    .product-cont{
      margin-left: unset!important;
      margin-right: unset!important;
    }
 .brands-section .swiper-slide {
      margin-right: 0px !important;
  }













  }



  .products-section {
    background-color: white;

    // padding: 20px 0px;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;

  }

  .swiper-container.swiper-container-initialized.swiper-container-horizontal.px-2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.allcatnew{
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.ftrdbrands{
  padding-left: 15px!important;
  padding-right: 15px!important;
}
  .all-categories-section {
    border-radius:unset!important;
    background-color: white;
    padding: 15px 0px;

    .category-view {
      margin-top: 10px;
      border: 1px solid #e2e5f1;
      border-radius: 3px;
      padding: 5px 10px;
      align-items: center;
      height: 40px;

      p {
        margin: 0px;
        color: #212529;
        // margin-left: 10px;
      }
    }
  }

  .featured-brands, .products-slider-1 {
    background-color: white;
    border-radius:unset!important;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .stage-heading {
    text-align: center;
    .title1 {
      font-size: 20px !important;
      font-family: 'Futura Heavy';
    }

    .title2 {
      font-size: 20px !important;

      font-family: 'Futura Heavy';
    }
  }

  // height: 100%;
  .emptyspacermob {
    height: 15px;
  }
  .emptyspacermob-small {
    height: 5px;
  }

  .freeshipmob p {
    font-size: 14px !important;
    color: rgb(255, 255, 255);
  }

  .freeshipmob {
    background-color: $form-btn-bg;
    border-radius: 5px;
    margin: 10px;
  }

  .img-cont {
    text-align: -webkit-center;

  }

  .imgofr {
    background-color: #dadada;
    height: 72px;
    width: 72px;
    border-radius: 50px;
    align-content: center;
    margin-bottom: 10px;
    img{
      width: 40px;
      transform: scaleX(-1);
    }
  }

  .imgofr h3 {
    font-size: 10px;
    text-align: center;
    padding-top: 13px;
    color: white;
    padding-bottom: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    animation: blinker 1s step-start infinite;
    text-transform: uppercase;
  }

  a.nav-link.store-menu {

    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #000 !important;
    cursor: pointer;
  }

  .imgofr span {
    display: block;
    font-size: 11px;
    color: black !important;
    font-weight: 600;
    text-align: center;
    font-family: 'Futura Heavy';
    letter-spacing: 0px;
  }

  .swiper-wrapper {
    align-items: center;
  }

  h2.section-title {
    font-size: 18px;
    font-family: 'Futura Heavy';
    font-weight: 700;
  }

  h3.section-title {
    font-size: 20px;
  }

  .img-cont .img img {
    padding: 10px;
    padding-bottom: 0px;
  }

  .swiper-container-multirow .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    bottom: -5px;
  }

  .brands-row img {
    height: 70vw;
    border: 1px solid #9e9e9e52;
  }

  .section-underline:after {
    bottom: 0px;
  }

  .best-sellers {
    background-color: cornsilk;
  }

  .bg-transparent {
    background: transparent;
  }

  .catsbot .ViewAllbtn a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.916666rem;
  }

  .sectiontitle-container-left {
    margin: unset;
    padding-left: unset;
    padding-right:unset;
  }

  .ViewAllbtn {
    font-weight: 400;
    color: white;
  }

  .ViewAllbtn a {
    color: white;
    font-size: 14px;
    text-decoration: underline;
    padding: 5px;
    font-weight: 500;
    text-decoration: none;
  }

  .ViewAllbtn a:hover {
    color: white;
    box-shadow: 0 0 0 0.2rem black;
    text-decoration: none;
  }


  .swiper-pagination-bullet-active {
    background-color: #003366;
    opacity: .3;
  }

  .swiper-pagination-bullet {
    margin: 0 2px !important;
    opacity: .1;
  }

  .fltright {
    position: relative;
  }

  .catsbot-row img {
    border: 1px solid #9e9e9e52;
  }

  .brands-list img {
    background-color: #D9D9D980;
    border-radius: 100px;
    border: 1px solid #ccc;
  }

  .brands-list .swiper-container.swiper-container-initialized.swiper-container-horizontal {
    padding-left: 15px;
    padding-right: 15px;
}

  .cons2 {
    // width: 186px;
  }

  .cons3 {
    // width: 186px;
    height: 177px !important;
  }

  .cons2row {
    padding-bottom: 10px;
  }

  .offer1 {
    padding-bottom: 15px;
  }

  .offer2 {
    height: 223px;
  }

  .offer3 {
    height: 223px;
  }

  .offer2row {}

  .offer3row {
    // padding-left: 0px;
  }

  .catsbot-row {
    display: flex;
    overflow-x: scroll;

    a {
      display: block;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .brands-row {
    display: flex;
    overflow-x: scroll;

    a {
      display: block;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0px 10px;
    z-index: 10;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #D4982B;
    height: 55px;

    .logo {
      display: inline-flex;
      // width: 120px;
      height: 29px;
    }

    .search {
      color: $form-btn-bg;
      font-size: 22px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .cart-col {
      .cart-counter {
        position: absolute;
        top: 6px;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        line-height: 1;
        background-color: #003366;
        color: white;
        font-size: 10px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .header-products-slider {
    margin-bottom: 15px;
    font-weight: 700;
    color: #7d252a;
    opacity: 1;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
    padding: 20px 0 0 0px;
    font-size: 18px;
  }

  .body {
    position: relative;
    top: 55px;
    // top: 0px;
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding-bottom: 10px;

    .cross-icon {
      margin-top: 6px;
      margin-right: 5px;
    }

    .getAppRow {
      display: flex;
      padding: 10px;
    }

    .appOpenBtn {
      margin-top: 5px;
      background-color: $form-btn-bg;
      color: white;
      text-decoration: none;
      width: 60px;
      text-align: center;
      font-size: 13px;
      border-radius: 4px;
      padding-top: 6px;
      justify-content: flex-end;
      height: 35px;
    }

    .text p {
      font-size: 12px;
      margin: 0px;
    }

    .location-icon {
      color: $p-color;
      font-size: 20px;
      padding-left: 10px;
    }

    .deliver-text {
      color: $head-color;
      font-size: 13px;
      margin-left: 5px;

      .highlighted-text {
        font-weight: bold;
      }
    }

    .homepage-main-banner-swiper {
      // height: 150px;
      margin: 10px !important;
      border-radius: 10px;
    }

    .img-cat {}

    .shop-text {
      color: #7d252a;
      padding-inline-start: 10px;
      font-weight: 700;
      font-size: 1.4333rem;
    }

    .view-text {
      margin-inline-end: 10px;
      font-size: 0.816666rem;
      color: #7d252a;
      font-weight: bold;
      border: 1px solid #7d252a;
      border-radius: 2px;
      padding: 6.5px 5px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .ouroffers{
      height: 127px;
      align-content: end;
      h3 {
        font-size: 0.7rem;
        font-family: 'Futura Heavy';
        color: black;
        margin-bottom: 5px !important;
      }
    }

 


    .category-cont {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill);
      gap: 10px 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;

      @media screen and (min-width: 320px) and (max-width: 340px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 340px) and (max-width: 460px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 460px) and (max-width: 540px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 540px) and (max-width: 600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 600px) and (max-width: 7680px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      .img-cont {
        width: 100%;

        .img {
          width: 100%;
          height: auto;
          max-height: 120px;
          background-color: #d4982b;
          padding: 10px;
          border-radius: 50%;

          img {
            height: auto;
          }
        }
      }

      .name {
        color: #404553;
        font-size: 9px;
        font-weight: 620;
        text-align: center;
      }
    }

    .product-cont {
      display: flex;
      flex-direction: row;
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
      overflow-x: auto;
      overflow-y: hidden;

      .image-cont {
        width: 160px;
        max-width: 160px;
        margin-inline-end: 10px;
      }
    }
  }
}