@import "../../../mixins.scss";
@import "../../../variables.scss";

.item-data-desktop {
  width: 100%;

  .product-breadcrumb-main {
    margin-bottom: 15px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .product-breadcrumb {
    font-size: 14px;
    color: #414552;
    text-decoration: none;
  }

  .gt-sign {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    color: #414552;
  }

  .item-title {
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 7px;
    @include for-mob-screen {
      font-size: 18px !important;
      letter-spacing: 0.3px;
      color: rgb(0, 0, 0);
    }
  }
  .dssstrip{
    width: 100%;
    height: auto;
  }
  span.inf {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
}

  .vendure-text123 a {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.2px;
    @include for-mob-screen {
      color: rgb(0, 0, 0);
    }
  }
  .vendure-text123 {
    @include for-mob-screen {
    padding-top: 10px;
    padding-bottom: 10px;
    }
}
  .video-thumbnail {
    background-color: lightgray !important;
    cursor: pointer;
  }

  .video-icon {
    font-size: 28px;
    background-color: lightgray;
  }

  .child-div-one {
    width: 15%;

    @include for-xxl-screen {
      height: 440px;
    }
    @include for-xl-screen {
      height: 440px;
    }
    @include for-lg2-screen {
      height: 440px;
    }
    @include for-lg1-screen {
      height: 400px;
    }
    @include for-md-screen {
      height: 400px;
    }
    @include for-mob-screen {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    .item-imgs {
      width: 75px;
      height: 90px;
      border-radius: 15px;
    }
    .active-item-border {
      border: 2px solid #003366;
      border-radius: 5px;
    }
    .inactive-item-border {
      border: 1px solid lightgray;
      border-radius: 5px;
    }
  }

  .child-div-one::-webkit-scrollbar {
    display: none;
  }
  .child-div-two {
    @include for-xxl-screen {
      width: 85%;
    }
    @include for-xl-screen {
      width: 85%;
    }
    @include for-lg2-screen {
      width: 85%;
    }
    @include for-lg1-screen {
      width: 85%;
    }
    @include for-md-screen {
      width: 85%;
    }
    @include for-mob-screen {
      width: 100%;
    }
  }
}
