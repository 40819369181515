@import "../../../mixins.scss";
@import "../../../variables.scss";

.header-mobile {
  @include for-mob-screen {
    display: none;
  }
}

.footer-mobile {
  @include for-mob-screen {
    position: absolute;
    display: none;
  }
}

.item-detail-page-mobile {
  margin-bottom: 80px;
  height: 90%;
  overflow-y: auto;

  .search-mobile {
    display: none;

    @include for-mob-screen {
      display: block !important;
    }
  }

  .header-to-show-on-mobile {
    display: none;

    @include for-mob-screen {
      display: block !important;
      background-color: white;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;

      .img {
        margin-left: 10px;
        margin-right: 10px;
        width: 35%;
        height: 8%;
      }

      .search {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .back-to-top {
    display: block;
    color: white;
    background-color: #5e5655;
    padding: 10px !important;
    letter-spacing: 0.5px;
  }
}

.service-modal-mobile {
  height: fit-content !important;
  padding: 20px;
  display: block !important;
  position: absolute !important;
  top: 50% !important;
  transform: translate(0, -50%);

  .rdtPicker {
    position: fixed;
  }

  .continue-btn {
    margin: auto;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    // margin-left: 2%;
    background-color: #003366;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .add-to-cart-text {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.qty-val-cont-mobile {
  @include for-desktop-screen {
    display: none !important;
  }

  position: fixed;
  left: 0;
  bottom: 130px;
  width: 100%;
  height: 70px;
  background-color: white;

  .qty-heading {
    color: #404553;
    font-size: 15px;
    font-weight: 700;
  }

  .qty-values-cont {
    display: flex;
    flex-direction: row;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    overflow-y: hidden;

    .qty-values {
      border: 1px solid rgb(56, 102, 223);
      border-radius: 2px;
      padding: 0px 10px;
      height: 30px;
      margin: 0px 5px;
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.quantity-cont-mobile {
  @include for-desktop-screen {
    display: none !important;
  }

  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 54px;
  width: 100%;
  height: 75px;
  background-color: rgb(255, 255, 255);
  box-shadow: #00000057 0px 0px 8px;

  .addtocartwrap-mob {
    width: 100%;
    padding: 5px;
    text-decoration: none;
  }

  .qty-cont-mobile {
    width: 55px;
    height: 75px;
    padding: 0px 10px;
    float: left;
    background-color: white;
    border: 1px solid rgb(241, 243, 249);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .qty-text {
      font-size: 13px;
      text-align: center;
      color: gb(141, 148, 167);
    }

    .qty-num {
      font-weight: 800;
      font-size: 14px;
      text-align: center;
      color: #404533;
    }

    .qty-arrow {
      font-size: 18px;
    }
  }

  .add-to-cont-mobile:first-child {
    margin-bottom: 0.5%;
  }

  .add-to-cont-mobile:last-child {
    margin-top: 0.5%;
  }

  .add-to-cont-mobile {
    width: 100%;
    height: 49.5%;
    float: right;
    background-color: #003366;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .add-to-cart-text {
      color: white;
      font-size: 13px;
      font-weight: bold;
    }
  }

  .buy-now-btn {
    background-color: #d4982b;
  }

  .get-inquiry-mobile {
    width: 100%;
    height: 100%;
    float: right;
    background-color: #4dc247;
    border-radius: 3px;
    display: flex;
    padding: 0px 10px;
    // justify-content: center;
    align-items: center;
    cursor: pointer;

    .add-to-cart-text {
      p {
        color: white;
        font-size: 15px !important;
        font-weight: 500;
        letter-spacing: 0.3px;
      }
    }
  }

  .out-of-stock {
    width: 100%;
    display: flex;
    justify-content: center;
    .add-to-cart-text {
      color: #dc3545;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .notify-me-btn {
    width: 100%;
    height: 55%;
    border: 1px solid rgb(160, 160, 190);
    border-radius: 5px;
    align-items: center;
    display: flex;
    padding: 8px;
    justify-content: center;
    cursor: pointer;

    .notify-me-btn-text {
      color: black;
      font-size: 18px;
      font-weight: bold;
    }
  }
}