@import "../mixins.scss";
@import "../variables.scss";

.brands-desktop {
  .brandsLogosSection img {
    height: unset !important;
    background-color: white;
    box-shadow: 0px 0px 1px 0px #ccc;
    margin: 5px;
    width: 160px;
    border-radius: 8px;
    transition: transform 1s;
  }
  .brandsLogosSection img:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
    border: 1px solid #003366;
  }

  .brandSearchWrapper {
    padding: 20px;
  }
  .brandSearchMain {
    width: 320px;
    height: 36px;
    line-height: 36px;
    border-radius: 2px;
    border: solid 1px rgba(151, 151, 151, 0.4);
    position: relative;
  }
  .SearchIcon {
    background: url(https://www.nykaa.com/media/wysiwyg/HeaderIcons/menusprite.png) -65px -36px
      no-repeat;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    width: 25px;
    vertical-align: text-top;
    position: absolute;
    top: 8px;
    left: 5px;
  }
  input#brandSearchBox[type="text"] {
    outline: 0;
    padding: 0 0 0 5px;
    border: none;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    height: 34px;
    line-height: 34px;
    width: 268px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
  }
  .divider {
    margin: 0px;
  }
  .brandsSection {
    padding: 20px;
    padding-top: 18px;
    padding-bottom: 0px;
  }
  .brandsLogosSection {
    background-color: #fcfaf4;
    padding: 18px;
  }

  .get-app-dropdown {
    position: absolute;
    right: 0;
    width: 150px;
  }
}
