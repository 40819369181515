@import "../../../mixins.scss";
@import "../../../variables.scss";

.customer-checkout-desktop {
  padding: 30px 45px 0px;

  .ship-to-heading {
    font-size: 16px !important;
    font-weight: bold;
  }

  .addresses-popup {
    padding: 0px !important;
    border-radius: 5px;
    overflow: hidden;

    .address-card:last-child {
      border: none;
    }

    .address-card {
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid #dee2e6;
      cursor: pointer;

      .user_name {
        font-size: 14px !important;
        line-height: 17px;
        text-transform: capitalize;
      }

      .info {
        font-size: 13px;
        line-height: 17px;
      }

      .view-edit-btn {
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 3px;
        padding: 0px 10px;
        width: 96px;
        text-align: center;

        p {
          font-size: 11px;
        }
      }
    }
  }

  .first-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include for-md-screen {
      padding: 30px 30px 0px;
    }

    @include for-lg1-screen {
      padding: 30px 45px 0px;
    }

    .payment-col {
      .addresses-card {
        border: none;
        border-radius: 0px;
        padding-top: 15px;
        padding-bottom: 15px;

        .address-card {
          // border-bottom: 1px solid rgba(0, 0, 0, .125);
          // border-radius: 5px;
          cursor: pointer;

          .user_name {
            font-size: 14px !important;
            line-height: 17px;
            text-transform: capitalize;
          }

          .info {
            font-size: 13px;
            line-height: 17px;
          }

          .view-edit-btn {
            border: 1px solid rgba(0, 0, 0, .125);
            border-radius: 3px;
            padding: 0px 10px;
            width: 75px;

            p {
              font-size: 11px;
            }
          }
        }
      }

      .add-address-btn {
        background-color: #003366;
        padding: 7px;
        cursor: pointer;
        text-align: center;

        p {
          color: white;
          font-weight: 700;
        }
      }

      .order-items-title {
        text-align: start !important;
      }
    }

    .summary-col {
      max-width: 30%;

      .active-text {
        color: #38ae04;
        font-family: "futura heavy";
        font-size: 12px;
      }

      .plain-text {
        font-family: "futura heavy";
        font-size: 12px;
      }

      .order-items {
        border: 1px solid gainsboro;
        max-height: 310px;
        overflow-y: scroll;
      }

      /* width */
      .order-items::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      .order-items::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
      }

      /* Handle */
      .order-items::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }

      /* Handle on hover */
      .order-items::-webkit-scrollbar-thumb:hover {
        background: grey;
      }

      @include for-md-screen {
        max-width: 100%;
      }

      @media (min-width: 992px) {
        &:dir(rtl) {
          margin-left: 50px;
        }

        max-width: 30%;
      }
    }
  }

  .shipping-row {
    .title {
      font-size: 14px;
    }

    .react-switch-bg {
      .react-switch-handle {
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    h2 {
      font-size: 1.875rem;
      font-weight: 700;
      color: $head-color;
    }

    .pay-with-alisuq-wallet {
      font-weight: 700;
      color: $head-color;
      margin-inline-start: 8px;
      .available-balance {
        color: #404553;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    button {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 48.5%;
      background-color: white;
      box-shadow: 0px 0px 2px #0000004d;
      border: 2px solid #003366;
      font-size: 1.109875rem;
      font-weight: 700;
      color: $head-color;

      svg {
        font-size: 1.109875rem;
        font-weight: 700;
        width: 27px;
        height: 21px;
        color: #38ae04;
        margin-inline-end: 8px;
      }
    }

    .collect-button {
      cursor: pointer;
      .title {
        color: #212529;
        font-size: 1rem !important;
        margin: 0px;
      }
      .text {
        opacity: 0.6;
      }
    }

    .card-button {
      svg.card-icon {
        width: 30px;
        height: 30px;
      }
    }

    .cash-button {
      svg.cash-icon {
        width: 30px;
        height: 30px;
      }
    }

    .gray-button {
      border: none;
      // opacity: 0.6;

      svg {
        font-weight: 700;
        color: #000000;
        margin-inline-end: 8px;
      }
    }

    .order-button {
      border: none;
      // background-color: $form-btn-bg;
      color: white;
      height: 57px;
      min-height: 57px;
      max-height: 57px;
    }

    .active-btn {
      background-color: $form-btn-bg;
    }

    .inactive-btn {
      background-color: #cbcdcf;
    }

    label {
      color: #80879f;
      font-size: 1rem;
      font-weight: 700;
    }

    input {
      background: green;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #e7effd;
      padding: 14px 10px;
      box-shadow: 0px 0px 2px #0000004d;

      &::placeholder {
        height: 20px;
        line-height: 20px;
        padding-top: 5px;
      }
    }

    .with-border {
      border: 1px solid $border-color;
      box-shadow: none;

      &::placeholder {
        text-align: center;
      }
    }
  }
.customer-info{
  background-color: #cccccc47;
    padding: 15px;

}
  .pickup-addresses {
    margin-top: 35px;
    margin-bottom: 35px;
    .active {
      border: 1px solid #003366 !important;
    }
    .pickup-address {
      
      cursor: pointer;
      border: 1px solid $border-color;
      padding: 10px 15px;
      display: flex;
      align-items: center;

      p {
        margin: 0px;
        margin-left: 10px;
      }
    }
  }
}


.add-address-modal {
  .modal-title {
    font-size: 1rem;
    font-weight: bold;
  }

  .modal-body {

    label,
    input,
    select,
    option {
      font-size: 14px;
    }

    .form-group {
      .react-tel-input {
        input {
          width: 100%;
        }
      }
    }
  }

  .modal-footer {
    .save-address {
      background-color: #003366;
      border: 1px solid #003366;
      font-size: 14px;
      font-weight: 700;
    }
  }
}