
.bottom-nav-my-account {
  background-color: white;
  display: flex;
  justify-content: space-around;

  padding: 8px 4px 0px 0px;
  height: 100%;
  .icon-label-set {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .mob-trend {
      width: 50px;
      max-width: 50px;
      height: 22px;
      max-height: 22px;
    }

    .icon-label {
      font-weight: 400;
      font-size: 0.5rem;
      margin-bottom: 0px;
      &::selection {
      }
    }
    .cart-col {
      text-decoration: none;
      position: relative;
      .cart-counter {
        left: 13px;
        position: absolute;
        top: -6px;
        right: -9px;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        line-height: 1;
        background-color: #003366;
        color: rgb(255, 255, 255);
        font-size: 10px;
        font-weight: 600;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
      }
    }
  }
}
